import React, { useEffect, useState } from "react";
import styled from "styled-components"

const PopupQuiz = () => {

  const [isShow, setIsShow] = useState(1)

  useEffect(() => {
    const popupModalValue = localStorage.getItem("popupModal")
    const old = localStorage.getItem("oldTime")
    var showPopup = 1;
    var now
    now = new Date().getTime();

      console.log("if > 900000 open the popup: ", now - old)
      if ( popupModalValue === "1" || popupModalValue === null || (now - old) > 900000) {
        showPopup = 1
        localStorage.setItem("oldTime", now)
        localStorage.setItem("popupModal", "1");
      } else {
        showPopup = 0
        localStorage.setItem("popupModal", "0");

      }

      setIsShow(showPopup)

  }, []);

  function ShowOrNot() {
    console.log('function')
    if(isShow){
      setIsShow(0);
      localStorage.setItem("popupModal", "0") 
    }
  }

  return (<Container>
    {
      isShow === 1 &&
      <div>
        <>
          <div className="overlay" onClick={() => ShowOrNot()}></div>
          <div>
            <div className="x" onClick={() => ShowOrNot()}>X</div>
            <img src="./images/popup/activitees-spacebowling.jpg" alt="quiz" /> 
          </div>
        </>
      </div>
    }
  </Container>
  )
}

const Container = styled.div`
>div{
  position: fixed;
  /* text-align: center; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 401;
  /* >div{ */
  >div{
    position: relative;

    /* top: 0;
    left: 0; */
    width: 900px;
    height: 800px;
    /* display: flex; 
    justify-content: center;
    align-items: center; */
    z-index: 405;
    @media (max-width: 900px) {
      width: 100%;
      height: auto;
    }
    @media (max-height: 840px) {
      width: auto;
      height: 700px;
    }
    @media (max-height: 720px) {
      width: auto;
      height: 600px;
    }
    @media (max-height: 620px) {
      width: auto;
      height: 500px;
    }

    >.x{
      position: absolute;
      color: white;
      z-index: 2000;
      font-size: 28px;
      font-weight: 800;
      right: 20px;
      top: 20px;
      padding: 10px;
      cursor: pointer;
    }
    >img{
      width: 100%;
      height: 100%;
    }
  }
    >.overlay{
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: grey;
      opacity: 0.5;
      z-index: 401;
    }
  /* } */
}
`;


export default PopupQuiz